// import React from 'react';
// import { arrayOf } from 'prop-types';
// import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
// import { propTypes } from '../../util/types';
// import Slider from 'react-slick';
// import { Avatar } from '../../components';

// import css from './CustomerReviews.module.css';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

// const ReviewItem = ({ review, listingImages }) => {
//   const listingId = review.listing.id.uuid;
//   const listingTitle = review.listing.attributes.title;
//   const slider = React.useRef(null);

//   const formatTitleForUrl = title => {
//     return title
//       .toLowerCase()
//       .replace(/[^a-z0-9]+/g, '-')
//       .replace(/^-+|-+$/g, '');
//   };

//   const imageSliderSettings = {
//     arrows: false,
//     infinite: true,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     adaptiveHeight: true,
//     swipe: false,
//     autoplay: true,
//     speed: 2000,
//     autoplaySpeed: 3000,
//     cssEase: 'linear',
//   };

//   const listingUrl = `/l/${formatTitleForUrl(listingTitle)}/${listingId}`;

//   return (
//     <div className={css.reviewItem}>
//       <div className={css.reviewHeader}>
//         <Avatar className={css.avatar} user={review.author} />
//         <div className={css.authorInfo}>
//           <span className={css.authorName}>{review.author.attributes.profile.displayName}</span>
//           <span className={css.reviewDate}>
//             {new Date(review.attributes.createdAt).toLocaleDateString()}
//           </span>
//         </div>
//       </div>
//       <div className={css.reviewContent}>
//         <p className={css.reviewText}>{review.attributes.content}</p>
//         <div className={css.rating}>
//           {[1, 2, 3, 4, 5].map(star => (
//             <span
//               key={star}
//               className={star <= review.attributes.rating ? css.starFilled : css.starEmpty}
//             >
//               ★
//             </span>
//           ))}
//         </div>
//       </div>
//       <div className={css.listingImageSliderContainer}>
//         <Slider ref={slider} {...imageSliderSettings}>
//           {listingImages.length > 0 ? (
//             listingImages.map((imageUrl, index) => (
//               <div key={index} className={css.listingImageSlide}>
//                 <button onClick={() => slider?.current?.slickPrev()}>Prev</button>
//                 <a href={listingUrl}>
//                   <img
//                     src={imageUrl}
//                     alt={`${listingTitle} - Image ${index + 1}`}
//                     className={css.listingImage}
//                   />
//                 </a>
//                 <button onClick={() => slider?.current?.slickNext()}>Next</button>
//               </div>
//             ))
//           ) : (
//             <div className={css.noImage}>No images available</div>
//           )}
//         </Slider>
//       </div>
//       <div className={css.listingTitle}>{listingTitle}</div>
//     </div>
//   );
// };

// ReviewItem.propTypes = {
//   review: propTypes.review.isRequired,
//   listingImages: arrayOf(propTypes.string).isRequired,
// };

// export const ListingReviewsComponent = props => {
//   const { reviews, intl } = props;

//   const settings = {
//     arrows: false,
//     dots: true,
//     infinite: false,
//     speed: 2000,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     adaptiveHeight: true,
//     lazyLoad: true,
//     swipe: true,
//   };

//   return (
//     <div className={css.root}>
//       <h2 className={css.title}>
//         <FormattedMessage id="ListingReviews.title" values={{ count: reviews.length }} />
//       </h2>
//       <Slider {...settings}>
//         {reviews.map(review => (
//           <ReviewItem
//             key={review.id.uuid}
//             review={review}
//             listingImages={review.listing.images || []}
//           />
//         ))}
//       </Slider>
//     </div>
//   );
// };

// ListingReviewsComponent.defaultProps = {
//   reviews: [],
// };

// ListingReviewsComponent.propTypes = {
//   reviews: arrayOf(propTypes.review),
//   intl: intlShape.isRequired,
// };

// const ListingReviews = injectIntl(ListingReviewsComponent);

// export default ListingReviews;

import React from 'react';
import { arrayOf } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import Slider from 'react-slick';
import { Avatar } from '../../components';

import css from './ListingReviews.module.css';

const ReviewItem = ({ review }) => {
  return (
    <div className={css.reviewItem}>
      <div className={css.reviewHeader}>
        <Avatar className={css.avatar} user={review.author} />
        <div className={css.authorInfo}>
          <span className={css.authorName}>{review.author.name}</span>
        </div>
      </div>
      <div className={css.reviewContent}>
        <p className={css.reviewText}>{review.content}</p>
        <div className={css.rating}>
          {[1, 2, 3, 4, 5].map(star => (
            <span key={star} className={star <= review.rating ? css.starFilled : css.starEmpty}>
              ★
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

ReviewItem.propTypes = {
  review: propTypes.review.isRequired,
};

const hardcodedReviews = [
  {
    author: { name: 'Sarah' },
    date: new Date(),
    content:
      "I ordered a custom cake from a local bakery for my son's birthday, and it was absolutely perfect! The bakery captured exactly what we wanted in terms of design and flavor. The cake looked amazing and tasted even better. Highly recommend their custom cakes for any special occasion!",
    rating: 5,
  },
  {
    author: { name: 'David' },
    date: new Date(),
    content:
      'We recently celebrated our anniversary with a gorgeous anniversary cake from a neighborhood bakery. It was beautifully decorated and tasted divine. The bakery did an incredible job, and we were thrilled with how everything turned out. Perfect for marking such a special milestone!',
    rating: 5,
  },
  {
    author: { name: 'Jessica' },
    date: new Date(),
    content:
      'My partner and I were blown away by the stunning wedding cake we had at our reception. It was everything we had hoped for and more. The bakery not only created a masterpiece in terms of design but also delivered on flavor. It was a highlight of our special day!',
    rating: 5,
  },
  {
    author: { name: 'Emily' },
    date: new Date(),
    content:
      'Hosting a baby shower was made even more special with the adorable baby shower cake we ordered. It was so cute and tastefully decorated. The bakery did an excellent job in bringing our vision to life. Everyone at the shower loved the cake!',
    rating: 5,
  },
  {
    author: { name: 'Michael' },
    date: new Date(),
    content:
      "We couldn't have been happier with the bridal shower cake we ordered for my sister's celebration. It was elegant and delicious, and the bakery really captured the essence of the occasion in the cake's design. It made the event truly memorable.",
    rating: 5,
  },
  {
    author: { name: 'Sophia' },
    date: new Date(),
    content:
      "Ordering a birthday cake for my daughter's party was stress-free thanks to the wonderful bakery we found. The cake was not only visually stunning but also incredibly tasty. It was the perfect centerpiece for the party and made my daughter's birthday extra special.",
    rating: 5,
  },
];

export const ListingReviewsComponent = props => {
  const { intl } = props;

  const settings = {
    arrows: false,
    dots: true,
    infinite: false,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    lazyLoad: true,
    swipe: true,
  };

  return (
    <div className={css.root}>
      <h3 className={css.title}>
        <FormattedMessage id="ListingReviews.title" values={{ count: hardcodedReviews.length }} />
      </h3>
      <Slider {...settings}>
        {hardcodedReviews.map((review, index) => (
          <ReviewItem key={index} review={review} />
        ))}
      </Slider>
    </div>
  );
};

ListingReviewsComponent.defaultProps = {
  reviews: hardcodedReviews,
};

ListingReviewsComponent.propTypes = {
  reviews: arrayOf(propTypes.review),
  intl: intlShape.isRequired,
};

const ListingReviews = injectIntl(ListingReviewsComponent);

export default ListingReviews;
