import React, { useState, useRef, useEffect } from 'react';
import css from './SeoGlendale.module.css';

const FAQ = props => {
  const { location } = props;
  const [activeIndex, setActiveIndex] = useState(null);
  const [height, setHeight] = useState(0);
  const contentRef = useRef(null);

  const questions =
    location === 'Pasadena'
      ? [
          {
            question: `What types of cakes are available in ${location}? `,
            answer: `Pasadena boasts a wide variety of cakes to suit every taste and occasion. Classic favorites like chocolate and vanilla are readily available, alongside specialty options like red velvet and carrot cake. For those seeking something unique, custom-designed cakes can be tailored for birthdays, weddings, and other celebrations, with designs ranging from elegant tiered cakes to whimsical character shapes.

      The city also caters to dietary needs with gluten-free, vegan, and keto-friendly cakes that don’t compromise on flavor. For an international flair, enjoy options like French Opera Cake or Italian Tiramisu Cake. Seasonal and themed cakes add festive touches to holidays, while mini cakes and cupcakes offer a convenient way to enjoy multiple flavors. Artisanal and gourmet cakes feature high-quality ingredients and exotic flavors, perfect for a refined dessert experience. Whether traditional or adventurous, Pasadena's bakeries provide delicious choices for every palate.`,
          },
          {
            question: `How can I order a custom cake in ${location}?`,
            answer: `Ordering a custom cake in Pasadena is easy and convenient. Start by researching local bakeries that offer custom cakes. Once you've found a few options, contact them to discuss your specific needs, including design preferences, desired flavors, and any dietary requirements. Many bakeries allow you to work directly with a baker or designer to ensure your cake matches your vision. Be sure to place your order well in advance, especially for large or intricate cakes, to ensure availability and enough time for preparation.`,
          },
          {
            question: `Do you offer delivery services for cakes in ${location}?`,
            answer: `Yes, delivery services for cakes in Pasadena are available, and they are handled by a dedicated service called Sweet Tooth. Whether you're celebrating at home or hosting an event, Sweet Tooth ensures your cake arrives fresh and on time. Just coordinate the delivery details when you place your order with your chosen bakery, and Sweet Tooth will take care of delivering the cake directly to your venue.`,
          },
          {
            question: `How much does a custom cake typically cost in ${location}?`,
            answer: `
1. Size and Servings
      The size of the cake is one of the primary factors determining the cost. Smaller cakes, such as those designed to serve 8-10 people, typically start around $30-$50. As the number of servings increases, so does the price. Larger cakes, such as those for weddings or large parties, can range from $100 to several hundred dollars, depending on the number of tiers and servings.

2. Design Complexity
      The complexity of the cake design plays a crucial role in pricing. Simple designs with minimal decoration are more affordable, while cakes with elaborate decorations, such as intricate piping, fondant work, sculpted shapes, or detailed edible art, can significantly increase the price. Custom elements like hand-crafted sugar flowers, 3D figures, or themed decorations add to the cost due to the time and skill required.
      
3. Ingredients and Flavors
      The choice of ingredients also impacts the price. Premium ingredients, such as high-quality chocolate, fresh fruit, exotic spices, or organic products, can make the cake more expensive. Additionally, specialty flavors or dietary accommodations, like gluten-free, vegan, or keto-friendly options, often require special ingredients or preparation methods, which can add to the cost. 
      
4. Additional Customization
      Any extra customizations, such as edible images, metallic finishes, or personalized toppers, contribute to the overall cost. Personalized elements like a specific cake shape (e.g., a favorite character or object) or custom messages can also increase the price.
      
5. Delivery and Set-Up
      If you require delivery and set-up, this service may come with an additional fee. Delivery costs depend on the distance to the venue and the complexity of the cake setup. Some bakeries in Pasadena partner with services like Sweet Tooth to handle deliveries, ensuring the cake arrives safely and on time.
      
6. Lead Time and Availability
      Rush orders may incur extra charges, especially if they require the bakery to prioritize your cake over other orders. Booking well in advance, particularly for highly sought-after dates or complex cakes, is advisable to secure availability and potentially better pricing.
      
In summary, while standard custom cakes in Pasadena might start around $30-$50, the price can vary widely based on the factors mentioned above. For a more accurate quote, it’s best to consult directly with the bakery, discuss your specific needs, and request a detailed estimate.`,
          },
        ]
      : [
          {
            question: `What types of cakes are available in ${location}? `,
            answer: `Explore a wide variety of cakes in ${location}, including classic flavors like chocolate and vanilla, specialty cakes such as red velvet and carrot cake, and custom-designed cakes perfect for birthdays, weddings, and other celebrations.`,
          },
          {
            question: `How can I order a custom cake in ${location}?`,
            answer: `Ordering a custom cake in ${location} is straightforward! Browse local bakeries offering personalized options, discuss your design preferences, flavors, and dietary requirements directly with the baker to create the perfect cake for your event.`,
          },
          {
            question: `Do you offer delivery services for cakes in ${location}?`,
            answer: `Yes, we provide delivery services for cakes in ${location}. Whether you're celebrating at home or hosting an event, local bakeries offer convenient delivery options to ensure your cake arrives fresh and on time to your venue.`,
          },
          {
            question: `How much does a custom cake typically cost in ${location}?`,
            answer: `Custom cake prices in ${location} vary based on size, design complexity, and ingredients. Prices generally start around $30-$50 for standard custom cakes and can increase with intricate decorations, specialty flavors, and additional customization requests.`,
          },
        ];

  useEffect(() => {
    if (activeIndex !== null) {
      setHeight(contentRef.current.scrollHeight);
    } else {
      setHeight(0);
    }
  }, [activeIndex]);

  const toggleAnswer = index => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className={css.faq}>
      <h2>Frequently asked questions</h2>
      {questions.map((item, index) => (
        <div key={index} className={css.faq_item}>
          <h3 className={css.faq_question} onClick={() => toggleAnswer(index)}>
            {item.question}
            <span className={css.faq_icon}>{activeIndex === index ? '-' : '+'}</span>
          </h3>
          <div
            ref={contentRef}
            className={`${css.faq_answer} ${activeIndex === index ? css.open : ''}`}
            style={{ height: activeIndex === index ? `${height}px` : '0px' }}
          >
            <div className={css.faq_answer_content}>{item.answer}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FAQ;
