import React, { useEffect } from 'react';
import ListingCard from '../../components/ListingCard/ListingCard';
import styles from './LatestListings.module.css';

const LocationListings = props => {
  const { notitle = false, location = null, latestListings = null } = props;

  return (
    <div className={styles.container}>
      {!notitle && <h2 className={styles.latestListingsHeader}>Trending Desserts</h2>}
      <div className={styles.listingCards}>
        {latestListings?.map(listing => (
          <ListingCard
            className={styles.listingCard}
            key={listing.id.uuid}
            listing={listing}
            showAuthorInfo={true}
          />
        ))}
      </div>
    </div>
  );
};

export default LocationListings;
