import React from 'react';
import css from './SeoGlendale.module.css';
import image1 from './assets/1.png';
import image2 from './assets/2.png';
import image3 from './assets/3.png';

const HowItWorks = () => {
  return (
    <div className={css.how_it_works}>
      <h3>How it works</h3>
      <div className={css.step}>
        <div className={css.step_icon}>📱</div>
        <img src={image1} alt="Order online" className={css.step_img} />
        <h4>Order online</h4>
        <p>
          Order through our website effortlessly, and for any specific needs, communicate directly
          with the baker or bakery using our messaging feature. Ensure everything is perfect for
          your upcoming event.
        </p>
      </div>
      <div className={css.step}>
        <div className={css.step_icon}>📦</div>
        <img src={image2} alt="Select pick up or delivery" className={css.step_img} />
        <h4>Select pickup or delivery</h4>
        <p>
          Select pickup or delivery to ensure your order arrives on time for your special occasion.
        </p>
      </div>
      <div className={css.step}>
        <div className={css.step_icon}>🎉</div>
        <img src={image3} alt="Celebrate!" className={css.step_img} />
        <h4>Celebrate!</h4>
        <p>
          Delight in your exquisite treats when they arrive promptly on your chosen delivery date,
          ideal for your important event.
        </p>
      </div>
    </div>
  );
};

export default HowItWorks;
