// import React from 'react';
// import { Link } from 'react-router-dom';
// import styled from 'styled-components';
// import css from './SeoGlendale.module.css';
// import { NamedLink } from '../../components';
// import { FormattedMessage } from '../../util/reactIntl';

// const SublocationsContainer = styled.div`
//   margin-top: 2rem;
// `;

// const SublocationsTitle = styled.h3`
//   font-size: 1.5rem;
//   margin-bottom: 1rem;
// `;

// const SublocationsList = styled.ul`
//   list-style-type: none;
//   padding: 0;
// `;

// const SublocationItem = styled.li`
//   margin-bottom: 0.5rem;
// `;

// const SublocationLink = styled(Link)`
//   text-decoration: none;
//   color: #007bff;
//   &:hover {
//     text-decoration: underline;
//   }
// `;

// function decodeLocationToUrl(formattedCityName) {
//   return formattedCityName
//     .split(' ')
//     .map(word => word.charAt(0).toLowerCase() + word.slice(1))
//     .join('-');
// }

// const Sublocations = ({ sublocations, location, category }) => {
//   console.log(sublocations, 'sublocations');
//   console.log(location);
//   return (
//     <div className={css.similar_searches}>
//       <SublocationsContainer>
//         <SublocationsTitle>Popular Areas in {location}</SublocationsTitle>
//         <SublocationsList>
//           {sublocations &&
//             sublocations.length > 0 &&
//             sublocations[0]?.sublocations?.map((sublocation, index) => (
//               <SublocationItem key={index}>
//                 {/* <SublocationLink to={`/${location}/${sublocation.url}`}>
//                   {sublocation.name}
//                 </SublocationLink> */}

//                 <NamedLink
//                   name="SubLocationPage"
//                   params={{
//                     location: decodeLocationToUrl(location),
//                     category: category,
//                     sublocation: sublocation.url,
//                   }}
//                 >
//                   <FormattedMessage
//                     id="Sublocations.sublocation"
//                     values={{ city: sublocation.name }}
//                   />
//                 </NamedLink>
//               </SublocationItem>
//             ))}
//           {(!sublocations || sublocations.length === 0 || !sublocations[0]?.sublocations) && (
//             <p>No popular areas available.</p>
//           )}
//         </SublocationsList>
//       </SublocationsContainer>
//     </div>
//   );
// };

// export default Sublocations;

import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import css from './SeoGlendale.module.css';
import { NamedLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

const SublocationsContainer = styled.div`
  margin-top: 2rem;
`;

const SublocationsTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const SublocationsList = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const SublocationItem = styled.li`
  box-sizing: border-box;
`;

const SublocationLink = styled(Link)`
  text-decoration: none;
  color: #007bff;
  &:hover {
    text-decoration: underline;
  }
`;

function decodeLocationToUrl(formattedCityName) {
  return formattedCityName
    .split(' ')
    .map(word => word.charAt(0).toLowerCase() + word.slice(1))
    .join('-');
}

const Sublocations = ({ sublocations, location, category, sublocation }) => {
  return (
    <div className={css.similar_searches}>
      <SublocationsContainer>
        <SublocationsTitle>
          {sublocation == ''
            ? `Order ${category} near ${location}`
            : `Order ${category} near ${sublocation}`}
        </SublocationsTitle>
        <SublocationsList>
          {sublocations &&
            sublocations.length > 0 &&
            sublocations[0]?.sublocations?.map((sub, index) => {
              // Skip this sublocation if it matches the current sublocation
              if (sublocation && sub.name === sublocation) {
                return null;
              }
              return (
                <SublocationItem key={index}>
                  <NamedLink
                    name="SubLocationPage"
                    params={{
                      location: decodeLocationToUrl(location),
                      category: category,
                      sublocation: sub.url,
                    }}
                  >
                    <FormattedMessage id="Sublocations.sublocation" values={{ city: sub.name }} />
                  </NamedLink>
                </SublocationItem>
              );
            })}
          {(!sublocations || sublocations.length === 0 || !sublocations[0]?.sublocations) && (
            <p>No popular areas available.</p>
          )}
        </SublocationsList>
      </SublocationsContainer>
    </div>
  );
};

export default Sublocations;
