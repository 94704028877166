import React, { Component } from 'react';
import { func, object, string, bool } from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';

import { intlShape, injectIntl } from '../../../util/reactIntl';
import { Form, LocationAutocompleteInput } from '../../../components';

import css from './TopbarSearchForm.module.css';

const identity = v => v;

class TopbarSearchFormComponent extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.searchInput = null;
    this.setSearchInputRef = element => {
      this.searchInput = element;
    };
  }

  onChange(location) {
    const { onSubmit } = this.props;
    if (location.selectedPlace) {
      onSubmit({ location });
      this.searchInput?.blur();
    }
  }

  render() {
    const { rootClassName, className, desktopInputRoot, intl, isMobile, locationcity } = this.props;
    const classes = classNames(rootClassName, className);
    const desktopInputRootClass = desktopInputRoot || css.desktopInputRoot;

    return (
      <FinalForm
        onSubmit={() => {}}
        render={() => (
          <Form className={classes} enforcePagePreloadFor="SearchPage">
            <Field
              name="location"
              format={identity}
              render={({ input, meta }) => {
                const { onChange, ...restInput } = input;
                const searchOnChange = value => {
                  onChange(value);
                  this.onChange(value);
                };

                return (
                  <div className={css.locationSearchBox}>
                    <LocationAutocompleteInput
                      className={isMobile ? css.mobileInputRoot : desktopInputRootClass}
                      iconClassName={isMobile ? css.mobileIcon : css.desktopIcon}
                      inputClassName={isMobile ? css.mobileInput : css.desktopInput}
                      predictionsClassName={
                        isMobile ? css.mobilePredictions : css.desktopPredictions
                      }
                      predictionsAttributionClassName={
                        isMobile ? css.mobilePredictionsAttribution : null
                      }
                      placeholder={`${locationcity}, CA`}
                      closeOnBlur={!isMobile}
                      inputRef={this.setSearchInputRef}
                      input={{ ...restInput, onChange: searchOnChange }}
                      meta={meta}
                    />
                  </div>
                );
              }}
            />
          </Form>
        )}
      />
    );
  }
}

TopbarSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
};

TopbarSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,
  appConfig: object.isRequired,
  intl: intlShape.isRequired,
};

const TopbarSearchFormLocation = injectIntl(TopbarSearchFormComponent);

export default TopbarSearchFormLocation;
